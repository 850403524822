import React from "react";
import SEO from "@components/seo";
import Layout from "@components/layout";
import Footer from "@components/footers/dark";
import MenuBar from "@components/menuBar/MenuBar";
import AddressCard from "./AddressCard";
import { GoogleMap, Marker, LoadScript, Autocomplete, InfoWindow } from "@react-google-maps/api";
import get from "lodash/get";
import _ from "lodash";
import haversine from "haversine";

class MyLocationMap extends React.Component {
  constructor(props) {
    super(props);

    this.autocomplete = null;
    this.onLoad = this.onLoad.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);

    this.state = {
      locations: get(this, 'props.data.allContentfulLocation.nodes'),
      locationsByDistance: [],
      activeMarker: null,
      mapCenter: { lat: -33.865531, lng: 151.208335 }
    }
  }

  computeDistances(origin) {
    const unsortedLocations = [];

    for (let i = 0; i < this.state.locations.length; i++) {
      let distance = haversine(origin, {latitude: this.state.locations[i].address.lat, longitude: this.state.locations[i].address.lon});

      unsortedLocations.push({
        ...{
          id: i,
          distance: {
            value: distance
          }
        },
        ...this.state.locations[i]
      });
    }

    let orderedByClosest = _.orderBy(unsortedLocations, ['distance.value'], ['asc']).slice(0, 5); // Show closest 5 locations
    this.setState({ locationsByDistance: orderedByClosest, mapCenter: {lat: origin.latitude, lng: origin.longitude} });
  }

  onLoad (autocomplete) {
    this.autocomplete = autocomplete
  }

  onPlaceChanged () {
    if (this.autocomplete !== null) {
      const search = this.autocomplete.getPlace();

      // handle user not selecting from dropdown (e.g. typing in address and hitting enter)
      if (search.geometry) {
        const searchCoordinates = search.geometry.location.toJSON();
        this.computeDistances({latitude: searchCoordinates.lat, longitude: searchCoordinates.lng})
        this.setState({ origin: searchCoordinates, originText: search.formatted_address });
      } else {
        // TODO: give user feedback that they need to select from dropdown
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  render() {
    const { locations, mapCenter, locationsByDistance, activeMarker } = this.state;

    return (
      <>
        <SEO title="Giftcards Locations" />
        <MenuBar/>
        <Layout>
          <section className="min-h-screen bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
              <h2 className="text-center text-3xl font-extrabold text-gray-900">Locations</h2>
              <LoadScript
                googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
                libraries={GoogleMapsLibraries}
              >
                <Autocomplete
                  onLoad={this.onLoad}
                  onPlaceChanged={this.onPlaceChanged}
                  fields={['name', 'formatted_address', 'geometry.location']}
                  restrictions={{ country: 'au' }}
                >
                  <input
                    type="text"
                    placeholder="Search by address or suburb"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight focus:border-highlight sm:text-sm"
                  />
                </Autocomplete>
                <div className="grid grid-cols-6">
                  {locationsByDistance.length > 0 && (
                    <div className="col-start-1 md:col-span-2 col-span-6 mb-8">
                      <ShowClosestLocations locationsToShow={locationsByDistance} onClickLocation={(location) => this.setState({activeMarker: location})} />
                    </div>
                  )}
                  <GoogleMap
                    zoom={12}
                    center={mapCenter}
                    mapContainerClassName={locationsByDistance.length > 0 ? 'map-container sm:col-start-1 md:col-start-3 md:col-span-4 col-span-6' : 'map-container col-span-6'}
                  >
                    {locations.map((location) => {
                      return (
                        <Marker
                          key={location.id}
                          position={{
                            lat: location.address.lat,
                            lng: location.address.lon
                          }}
                          onClick={ () => {this.setState({activeMarker: location})} }
                        />
                      );
                    })}
                    {activeMarker &&
                      <InfoWindow
                        position={{
                          lat: activeMarker.address.lat,
                          lng: activeMarker.address.lon
                        }}
                        onCloseClick={() => this.setState({activeMarker: null})}
                      >
                        <AddressCard
                          name={activeMarker.name}
                          StreetAddress={activeMarker.streetAddress}
                          suburb={activeMarker.suburb}
                          state={activeMarker.state}
                          postcode={activeMarker.postcode}
                          webstore={activeMarker.webstore}
                          phone={activeMarker.phone}
                        />
                      </InfoWindow>
                    }
                  </GoogleMap>
                </div>
              </LoadScript>
            </div>
          </section>
          <Footer/>
        </Layout>
      </>
    )
  }
}

export default MyLocationMap

const GoogleMapsLibraries = ['places'];

const ShowClosestLocations = ({ locationsToShow, onClickLocation }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-4">
      {locationsToShow.map((location) => {
        return (
          <AddressCard key={location.id}
            name={location.name}
            streetAddress={location.streetAddress}
            suburb={location.suburb}
            state={location.state}
            postcode={location.postcode}
            webstore={location.webstore}
            phone={location.phone}
            onClick={() => onClickLocation(location)}
          />
        );
      })}
      <a href="/locations/nsw" className="inline-flex px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-highlight hover:bg-highlight-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight">
        See all locations
      </a>
    </div>
  )
}

export const pageQuery = graphql`
  {
    allContentfulLocation {
      nodes {
        id
        name
        address {
          lat
          lon
        }
        streetAddress
        suburb
        state
        postcode
        webstore
        phone
      }
    }
  }
`
