import React from "react";

export default function AddressCard({ name, streetAddress, suburb, state, postcode, webstore, phone, onClick }) {
  return (
    <div className={"bg-white shadow sm:rounded-lg" + (onClick ? ' cursor-pointer' : '')} onClick={onClick}>
      <div className="px-4 py-4 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {name}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-700">
          <p>{streetAddress}</p>
        </div>
        <div className="mt-2 max-w-xl text-sm text-gray-700">
          <p>{suburb} {state} {postcode}</p>
        </div>
        {
          phone &&
          <div className="mt-3 text-sm">
            Ph
            <a href={`tel:${phone}`} className="font-medium text-indigo-600 hover:text-indigo-500">
              {' '}
              {phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2 $3")}
            </a>
          </div>
        }
        {
          webstore &&
          <div className="mt-3 text-sm">
            <a href={webstore} target="_blank" rel="noreferrer" className="font-medium text-indigo-600 hover:text-indigo-500">
              {' '}
              Buy a Gift Card Online <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        }
      </div>
    </div>
  )
}
